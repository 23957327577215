import { render, staticRenderFns } from "./CouponDetailPanel.vue?vue&type=template&id=1fe317d9&scoped=true"
import script from "./CouponDetailPanel.vue?vue&type=script&lang=js"
export * from "./CouponDetailPanel.vue?vue&type=script&lang=js"
import style0 from "./CouponDetailPanel.vue?vue&type=style&index=0&id=1fe317d9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fe317d9",
  null
  
)

export default component.exports